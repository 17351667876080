body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.rbc-event-content {
  white-space: break-spaces !important;
}
.loading .rbc-time-view,
.loading .rbc-month-view {
  opacity: 0.2;
}

/* white spaces when changing pages fix */
div[data-testid="promotions-accordion"]
  thead.MuiTableHead-root
  > tr.MuiTableRow-head {
  background-color: #000;
}
