.App {
  text-align: left;
}

.App-link {
  color: #61dafb;
}
form {
  width: 100%;
}
tr:nth-child(even) {
  background-color: #efefef !important;
}
thead th {
  position: sticky;
  top: 0;
  background-color: #0c2133;
  color: #ffffff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
#howToRedeem .ql-toolbar.ql-snow {
  display: none;
}
#howToRedeem .ql-editor {
  border-top: 1px solid #ccc;
}
